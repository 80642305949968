import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`During the winter holidays, the celebrations that most often come to our minds are Christmas, Hanukkah and the Chinese New Year. While those are important celebrations, they aren’t the only ones, in fact, there are dozens of festivities all around the world celebrating different cultures, traditions and values. Today more than ever, after a really harsh year, it is important to show unity and support to one another; friends, family, strangers or even people on the other side of the globe. In these  times, let’s show gratitude for the immense diversity of people and cultures in our world and do so by learning about one another.`}</p>
    <h3 {...{
      "id": "kwanzaa",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#kwanzaa",
        "aria-label": "kwanzaa permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Kwanzaa`}</h3>
    <p>{`Kwanzaa is a African American cultural celebration that lasts a week during winter holidays. The celebration was first introduced by Dr. Karenga in 1966, in the state of California. Karenga wanted to honor the African cultural heritage of the African American community. It was also created in response to the Watts riots of 1965. Dr. Karenga thought that Africans living outside of Africa were beginning to detach from the original values and culture of Africa. Kwanzaa is celebrated through traditional means of singing, dancing, African drumming, storytelling, poetry reading and feasting. The name Kwanzaa comes from matunda ya kwanza, which means first fruits or harvest in Swalihi. The festivity is based on seven main principles taken from original African harvest celebrations; Umoja- Unity, Kujichagulia- Self-Determination,  Ujima- Collective Work and Responsibility, Ujamaa- Cooperative Economics, Nia- Purpose, Kuumba- Creativity and Imani- Faith. The principles of Kwanzaa are representative values of the African culture that help reinforce the African community in the United States. Each day from the 26th of December to the 1st of January, a principle is discussed and praised, as well as that, for each day and principle, a candle is lit on the traditional kinara (candleholder). On the last day of the festive week, families share a big African feast called karamu. Although this celebration might seem fairly new, it honors long-established traditions and values, helping reinforce the appreciation and honoring of the African heritage.`}</p>
    <h3 {...{
      "id": "st-lucias-day",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#st-lucias-day",
        "aria-label": "st lucias day permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`St. Lucia’s day`}</h3>
    <p>{`St. Lucia’s day is a Scandinavic celebration in honour of St. Lucia, one of the earliest Christian martyrs. Centuries ago, nordic countries celebrated the winter solstice with big bonfires as a way to scare off evil spirits. When Christianity was introduced in those countries, the legend of St. Lucia became the main part of the celebrations. Nowadays, the festivity includes both elements from pagan and christian traditions. The classical food prepared for the occasion, are Lussekatter which are saffron buns. The tradition in scandinavian towns is to elect a St. Lucia, which will lead boys and girls through the town. Girls are usually dressed in white dresses, red sashes tied around their waist and wreaths with lighted candles on their heads, while boys will wear a white costume along with a tall hat decorated with golden stars. Traditionally, every family’s eldest daughter becomes the St. Lucia of the household for the celebration. The festival of St. Lucia marks the beginning of the Christmas period in Scandinavia and is also a celebration of light during the darkest period of the year.`}</p>
    <h3 {...{
      "id": "dongzhi-festival",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#dongzhi-festival",
        "aria-label": "dongzhi festival permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`DongZhi Festival`}</h3>
    <p>{`The DongZhi Festival is mostly celebrated in Eastern Asia. It is one of the most important winter celebrations for Chinese, Japanese, Korean, Vietnamise and Taiwanese people. This festival is related to the winter solstice and usually occurs on the 21st, 22nd or 23rd of December. Families get together and celebrate the arrival of longer daylight hours. Dōngzhì is the name of the 22nd solar term which marks the winter solstice. This celebration is said to be tied to the origins of the yin and yang philosophy of balance and harmony, it is believed that days with more daylight bring more happiness and positive energy which balances with the long dark days of winter. For DongZhi, families usually get together to share a meal. Traditionally, in Southern China people cook and eat tangyuan, which are balls of glutinous rice flour usually colored in pink or white. Tangyuans are usually also served with a warm bowl of soup. They are a symbol of family unity and prosperity. While in Northern China, the tradition is to go to the temples and tombs in order to offer food and drinks to the spirits of the ancestors as well as clean and maintain the tombs. Afterwards, the custom is to get together with the community to eat dumplings that contain meat and herbs. For Taiwanese people, the custom is similar since they also go to the tombs and offer tangyuans as well as small nine layers cakes to the spirits of their ancestors. During this period of the year, it is a custom in Asian countries, more specifically in China, to rest, relax and nourish your body with high fatty food to go through the long cold winter.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      